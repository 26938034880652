<script setup>
const { data: siteData } = await useAsyncData('stack', () =>
  queryContent('/sitedata').findOne(),
)
</script>
<template>
  <div class="bg-[#1d1729] text-[#ebe5e8] w-full text-sm lg:flex hidden">
    <div class="w-[98%] mx-auto px-10 py-4">
      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-6">
          <a
            :href="'mailto:' + siteData.email"
            class="flex gap-2 items-center hover:text-purple-500"
          >
            <Icon name="material-symbols:attach-email" />
            <span>{{ siteData.email }}</span>
          </a>
          <p class="flex gap-2 items-center hover:text-purple-500">
            <Icon name="material-symbols:pin-drop-rounded" />
            {{ siteData.address }}
          </p>
        </div>

        <div class="flex items-center space-x-6">
          <a
            v-for="(item, index) in siteData.social_media"
            :key="index"
            :href="item.link"
            class="hover:text-purple-500 flex items-center gap-1"
          >
            <Icon :name="item.icon" />
            <span>{{ item.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
