export default defineAppConfig({
  appName: 'Ekopii',
  appDescription: `Ekopii is a technology company based in Bangladesh that provides various digital solutions and services to its clients.The company’s primary goal is to help clients leverage technology to achieve their business objectives, such as improving efficiency, increasing productivity, and enhancing customer experience.`,
  profilePicture: null,
  footerName: 'Ekopii',
  email: 'info@ekopii.com',
  twitterUsername: '',
  phone: '(+880) 1623-148434',
  openGraphImage: '/social-preview.jpg',
  socials: {
    github: '',
    twitter: '',
    linkedin: '',
  },
  ui: {
    primary: 'emerald',
    gray: 'zinc',
    notifications: {
      position: 'top-0 bottom-auto',
    },
    notification: {
      progress: {
        base: 'absolute bottom-0 end-0 start-0 h-0',
        background: 'bg-transparent dark:bg-transparent',
      },
    },
    input: {
      variant: {
        none: 'bg-gray-100 dark:bg-gray-900/40 border-1 border-gray-700 hover:border-gray-400 focus:border-gray-400 transition-colors duration-300 ease-in-out',
      },
    },
    textarea: {
      variant: {
        none: 'bg-gray-100 dark:bg-gray-900/40 border-1 border-gray-700 hover:border-gray-400 focus:border-gray-400 transition-colors duration-300 ease-in-out',
      },
    },
  },
})
