<script setup>
const appConfig = useAppConfig()
const { data: siteData } = await useAsyncData('stack', () =>
  queryContent('/sitedata').findOne(),
)

const menuItem = ref([
  {
    name: 'Home',
    to: '/',
    icon: 'lucide:home',
  },
  {
    name: 'About',
    to: '/about',
    icon: 'lucide:user',
  },

  {
    name: 'Contact',
    to: '/contact',
    icon: 'lucide:mail',
  },
])

const quickLink = ref([
  {
    name: 'Services',
    to: '/services',
    icon: 'lucide:home',
  },
  {
    name: 'Solutions',
    to: '/solutions',
    icon: 'lucide:user',
  },
])
</script>
<template>
  <footer class="bg-[#1d1729] text-[#ebe5e8] py-16 px-3 lg:px-32">
    <div class="container mx-auto px-4">
      <!-- <div
        class="grid grid-cols-1 lg:grid-cols-6 items-center gap-4 bg-[rgb(48,40,63)] py-6 px-8 rounded-lg mb-16"
      >
        <div class="col-span-1">
          <div class="flex gap-5 justify-center">
            <span class="text-4xl font-bold text-purple-500">
              {{ appConfig.appName }}
            </span>
          </div>
        </div>
        <div class="col-span-1 lg:col-span-2 text-2xl font-bold text-center">
          <p class="text-gray-100 mt-2">
            Subscribe now to Get <br class="hidden lg:block" />
            Latest Updates
          </p>
        </div>

        <div class="col-span-1 lg:col-span-3">
          <form class="flex items-center gap-2">
            <input
              type="email"
              placeholder="Email Address"
              class="bg-transparent border-b border-gray-500 text-sm text-white focus:outline-none focus:border-purple-500 placeholder-gray-400 mr-4 w-full"
            />
            <button
              type="submit"
              class="text-purple-500 hover:text-white transition duration-300"
            >
              <i class="fas fa-paper-plane text-lg"></i>
            </button>
          </form>
        </div>
      </div> -->

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
        <div>
          <h3 class="text-xl font-semibold mb-4 text-white">About</h3>
          <p class="text-base text-gray-400 leading-relaxed mb-4">
            {{ siteData.site_subtitle }}
          </p>
          <div class="flex space-x-4">
            <a href="#" class="text-white hover:text-purple-500">
              <i class="fab fa-twitter text-2xl"></i>
            </a>
            <a href="#" class="text-white hover:text-purple-500">
              <i class="fab fa-facebook text-2xl"></i>
            </a>
            <a href="#" class="text-white hover:text-purple-500">
              <i class="fab fa-pinterest text-2xl"></i>
            </a>
            <a href="#" class="text-white hover:text-purple-500">
              <i class="fab fa-instagram text-2xl"></i>
            </a>
          </div>
        </div>

        <div class="ml-0 lg:ml-16">
          <h3 class="text-xl font-semibold mb-4 text-white">Explore</h3>
          <ul class="space-y-2 text-base">
            <li v-for="(item, index) in menuItem" :key="index">
              <NuxtLink
                :to="item.to"
                class="text-sm text-gray-400 hover:text-purple-500"
              >
                {{ item.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div class="">
          <h3 class="text-xl font-semibold mb-4 text-white">Quick Links</h3>
          <ul class="space-y-2 text-base">
            <li v-for="(item, index) in quickLink" :key="index">
              <NuxtLink
                :to="item.to"
                class="text-sm text-gray-400 hover:text-purple-500"
              >
                {{ item.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="text-xl font-semibold mb-4 text-white">Contact</h3>
          <p class="flex items-center gap-2 text-base text-gray-400 mb-2">
            <Icon name="material-symbols:add-location-alt-outline" />
            <span>{{ siteData.address }}</span>
          </p>
          <p class="flex items-center gap-2 text-base text-gray-400 mb-2">
            <Icon name="material-symbols:attach-email-outline-sharp" />
            <span>{{ siteData.email }}</span>
          </p>
          <p class="flex items-center gap-2 text-base text-gray-400">
            <Icon name="material-symbols:call" />
            <span>{{ siteData.phone }}</span>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
